@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");
.img-bg body {
  /* background: #fff; */
  font-size: 14px;
  color: #716e6e !important;
  font-family: "Raleway", sans-serif !important;
  background: url(../../../public/assets/images/bg1.webp) no-repeat 50% fixed !important;
  background-size: cover !important;
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 100px;
  min-height: 100%;
  position: relative;
  margin: 0;
  letter-spacing: inherit;
}
.img-bg .widthadjs {
  width: 59%;
  margin: 0 auto;
}
.img-bg .pos-rel {
  position: relative;
}
.img-bg .translation-box {
  position: absolute;
  right: 10px;
  bottom: 12px;
}
.img-bg .container {
  position: relative;
}
/* Dynamic Css Start  */
.img-bg .white-box {
  border: 4px solid #000;
}
.img-bg .blue {
  color: #000 !important;
  text-decoration: none !important;
}
.img-bg .blue:hover {
  color: #000 !important;
  text-decoration: underline !important;
}
.img-bg .translation-text {
  color: #4863ad;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-top: 5px;
  padding-bottom: 2px;
}
.img-bg .goog-te-gadget .goog-te-combo {
  color: black;
  padding: 5px;
  border-radius: 4px;
}
.img-bg .goog-te-gadget {
  font-family: arial;
  font-size: 0px;
  color: #6660 !important;
  white-space: nowrap;
}
.img-bg .goog-logo-link,
.img-bg .goog-logo-link:link,
.img-bg .goog-logo-link:visited,
.img-bg .goog-logo-link:hover,
.img-bg .goog-logo-link:active {
  font-size: 0px !important;
  font-weight: bold;
  color: #fff0 !important;
  text-decoration: none;
}
.img-bg .goog-te-gadget img {
  display: none !important;
}
.img-bg b.widthfont {
  font-weight: 500;
}
.img-bg .footer {
  background-color: #000;
}
.img-bg input.sub-btn:hover {
  background: #6e6f6f !important;
  border: 1px solid #6e6f6f !important;
}
.img-bg .white-box h1 {
  color: #000;
}
.img-bg .fl.wid100 textarea {
  max-width: 100%;
}

/* Dynamic Css End  */
.img-bg a.btn.btn-md.btn-primary {
  position: static !important;
  outline: none !important;
  height: 46px !important;
  padding: 8px !important;
  width: 118px !important;
  background: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
  font-weight: 500;
  float: right !important;
  border-radius: 30px !important;
}
.img-bg a.btn.btn-md.btn-primary:hover {
  background: #6e6f6f !important;
  border: 1px solid #6e6f6f !important;
  color: #fff !important;
  text-decoration: none !important;
}
.img-bg .mt-2 {
  margin-top: 0.5rem !important;
}

.img-bg {
  height: 100%;
  box-sizing: border-box;
}
.img-bg form#signin {
  margin-bottom: 0px;
}
.img-bg .white-box.thank {
  text-align: center;
}
.img-bg .reward label {
  min-height: 64px !important;
}
.img-bg .white-box-n h1 {
  font-size: 41px !important;
  font-weight: 600 !important;
  text-align: center;
  font-style: italic;
  margin-bottom: 27px;
  color: #000 !important;
}
.img-bg .white-box-n h2 {
  color: red !important;
  font-weight: bold !important;
  font-size: 16px !important;
  text-align: center !important;
}
.img-bg .white-box-n img {
  margin-bottom: 20px;
}
.img-bg .white-box-n {
  text-align: center;
}
.img-bg .white-box-n button:hover {
  background: #16419c;
  border: 1px solid #223d99;
}
.img-bg .white-box-n p {
  color: red;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.img-bg .white-box-n button {
  background: #00a8e2;
  border: 1px solid #00a8e2;
  outline: none;
  color: #fff;
  padding: 6px 40px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
}
.img-bg .reward img {
  width: 120px;
}
.img-bg i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.img-bg .mt-2 {
  margin-top: 20px;
}
.img-bg input,
.img-bg submit,
.img-bg button {
  box-shadow: none !important;
}
.img-bg a {
  color: #000;
  /* text-decoration: none !important; */
}
.img-bg a:hover {
  color: #000;
  text-decoration: underline;
}
.img-bg .cat {
  height: 100%;
  display: flex;
  align-items: center;
}
.img-bg ul.points li:first-child {
  border: none;
}
.img-bg h1 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.img-bg h2,
.img-bg h3,
.img-bg h4,
.img-bg h5,
.img-bg h6 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.img-bg ul.points li a:hover {
  color: #25408f;
}
.img-bg ul.points li span.bottom {
  background: #6e6f6f;
  padding: 14px;
  display: inline-block;
  width: -webkit-fill-available;
  color: #fff !important;
  text-align: center;
}
.img-bg .glyphicon-search:before {
  content: "\e003";
  font-family: "Glyphicons Halflings";
}
.img-bg select {
  outline: none !important;
}
.img-bg h1,
.img-bg h2,
.img-bg h3,
.img-bg h4,
.img-bg h5,
.img-bg h6,
.img-bg p {
  margin-top: 0;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0;
}
.img-bg p {
  line-height: 20px;
  color: #000;
}
.img-bg p:last-child {
  margin-bottom: 0px;
}
.img-bg h1 {
  font-size: 30px;
}
.img-bg h2 {
  font-size: 26px;
}
.img-bg h3 {
  font-size: 24px;
}
.img-bg h4 {
  font-size: 20px;
}
.img-bg h5 {
  font-size: 18px;
}
.img-bg h6 {
  font-size: 16px;
}
.img-bg :before,
.img-bg :after {
  font-family: Material-Design-Iconic-Font;
}
.img-bg p.note {
  background: #f68d2d;
  padding: 14px;
  color: #fff;
}
.img-bg ul.info-data {
  padding: 0px;
  list-style: none;
  margin: 10px auto;
  display: inline-block;
}
.img-bg ul.info-data li {
  border: 1px solid #ccc;
  color: #212121;
  margin-top: -1px;
  padding: 4px 52px;
}
.img-bg .clear:after {
  content: "";
  width: 100%;
  clear: both;
  display: table;
}
.img-bg .logo img {
  margin: 20px auto;
  /* width: 360px; */
}
.img-bg .white-box {
  background: rgba(255, 255, 255, 0.85);
  padding: 30px 30px;
  border-width: 4px 0px;
}
.img-bg .white-box h1 {
  color: #6e6f6f;
  font-size: 27px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}
.img-bg .white-box h2 {
  color: #010101;
  font-size: 17px;
  font-weight: 600;
}
.img-bg ul.points {
  padding-left: 0px;
  width: 100%;
}
.img-bg ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}
.img-bg ul.points img {
  width: 55px;
}
.img-bg ul.points li a {
  color: #000000;
  text-decoration: underline;
}
.img-bg ul.points li a:hover {
  color: #25408f;
}
.img-bg ul.points li:last-child {
  border-bottom: 0px;
}
.img-bg form.form-outer label {
  width: 100%;
  text-transform: uppercase;
}
.img-bg form.form-outer .adj-high label {
  height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0px !important;
}
.img-bg .text-with-img {
  color: #716e6e !important;
  display: block;
  font-size: 14px !important;
  font-weight: 400 !important;
  /* padding-bottom: 10px; */
  padding-left: 25px;
  position: relative;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
}
.img-bg .text-with-img input {
  position: absolute;
  left: 0;
  top: 0px;
}
.img-bg form.form-outer .removing label {
  font-weight: 700;
}
.img-bg form.form-outer label span.carddetail {
  display: block;
}
.img-bg .red_point {
  color: #f00;
}
.img-bg .error_message_forimage {
  width: auto !important;
  padding: 0 !important;
  float: left;
}
.img-bg form.form-outer input[type="text"],
.img-bg form.form-outer input[type="email"],
.img-bg form.form-outer input[type="tel"],
.img-bg form.form-outer select {
  background: #e8e8e8 !important;
}
.img-bg form.form-outer input[type="text"],
.img-bg form.form-outer input[type="email"],
.img-bg form.form-outer input[type="tel"],
.img-bg form.form-outer select {
  background: #ffffff00;
  width: 100%;

  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #dadada;
}
.img-bg .input-outer1 {
  position: relative;
  margin: 0px 0px;
}
.img-bg .input-outer {
  position: relative;
  margin: 20px 0px;
}
.img-bg form.form-outer button {
  /* position: absolute; */
  outline: none;
  right: 0%;
  border-radius: 30px;
  outline: none;
  height: 46px;
  width: 110px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  top: 37px;
}
.img-bg input.sub-btn {
  margin-top: 5px;
  color: #fff;
  width: 73px !important;
  position: absolute;
  right: 0px;
  padding: 10px;
  border-radius: 30px;
  top: 38px;
  border: 1px solid #000;
  background: #000 !important;
}
.img-bg p.logo-text {
  font-size: 45px;
  line-height: 1.4;
  font-weight: 700;
  color: #3e3934;

  margin-top: 13px;
}
.img-bg p.error {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  display: inline-block;
}
.img-bg form.form-outer button:hover {
  background: #6e6f6f;
  border: 1px solid #6e6f6f;
}
.img-bg .footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
.img-bg div#inlineContent img {
  width: 300px;
}
.img-bg div#ob_content p {
  text-align: center;
}
.img-bg .footer p {
  color: #fff;
  padding: 15px 0px;
}
.img-bg .white-box.form h2 {
  border: 1px solid #ccc;
  margin-top: 25px;
  padding: 10px 0px;
  border-width: 1px 0px 1px 0px;
}
.img-bg form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
.img-bg form.form-outer.register label span {
  color: #f00;
  font-size: 23px;
}
.img-bg .phone-outer input {
  width: 30% !important;
}
.img-bg input#choicekw0 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg input#choicekw1 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg input#choicekw2 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg form.form-outer.register select {
  background: #ffffff00;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #231f20;
}
.img-bg input#choicekw3 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg .reward input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}
.img-bg .reward1 img {
  width: 120px;
}
.img-bg .reward1 input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}
.img-bg .reward1 {
  max-height: 310px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  height: 200px;
}
.img-bg input.submit-btn:hover {
  background: #000000;
  border: 1px solid #000000;
}
.img-bg input.submit-btn {
  background: #3e3935;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #3e3935;
  border-radius: 50px;
}
.img-bg .reward {
  max-height: 160px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;

  margin-bottom: 10px;
  height: 295px;
}
.img-bg form.form-outer.register button {
  position: static !important;
  border-radius: 30px;
  height: 46px;
  width: 110px;
  background: #231f20;
  border: 1px solid #211d1e;
  color: #fff;
  margin-top: 31px;
  float: right;
}
.img-bg form.form-outer.register button:hover {
  background: #000000;
  border: 1px solid #000000;
}
/*Responsive table*/

@media (min-width: 992px) and (max-width: 1199px) {
  .img-bg .container-fluid {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-bg .logo img {
    margin: 20px auto !important;
    width: 360px;
  }
  .img-bg p.logo-text {
    float: right;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  .img-bg ul.points li img {
    margin-bottom: 10px;
  }
  .img-bg .container-fluid {
    width: 90%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .img-bg .white-box {
    text-align: center;
  }
  .img-bg .logo img {
    margin: 20px auto !important;
    width: 270px;
    float: none;
  }
  .img-bg p.logo-text {
    text-align: center;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 700;
    color: #3e3934;
    margin-top: 13px;
    margin-bottom: 30px;
  }
  .img-bg .phone-outer input {
    width: 28.6% !important;
  }
  .img-bg ul.points li img {
    margin-bottom: 10px;
  }
  .img-bg .container-fluid {
    width: 90%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .img-bg .logo img {
    margin: 20px auto !important;
    width: 360px;
  }
  .img-bg p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  .img-bg .phone-outer input {
    width: 28.6% !important;
  }
}
.img-bg .logo {
  text-align: center;
}
.img-bg h1 {
  text-align: center;
}
.img-bg .white-box {
  width: 100%;
  margin: 0 auto;
}
.img-bg .container-fluid {
  width: 60% !important;
}
.img-bg .phonenumber {
  font-size: 14px;
  font-family: Arial;
}
.img-bg .recap {
  width: 304px;
  height: 78px;
}
.img-bg .styling {
  width: 250px;
  height: 40px;
  border: 1px solid rgb(193, 193, 193);
  margin: 10px 25px;
  padding: 0px;
  resize: none;
  display: none;
}
.img-bg i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.img-bg .errorMsg {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  text-transform: none;
  font-weight: 400;
}
.img-bg input#myCheck {
  display: inline-block;
  width: auto;
}
.img-bg .thank svg {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.img-bg ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131 !important;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4 !important;
}

.likely-rate label {
  width: 10% !important;
  float: left;
}
.likely-rate span {
  width: 50%;
  float: left;
  font-size: 15px;
  font-weight: bold;
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .img-bg .container-fluid {
    width: 100%;
  }
}
.img-bg b,
.img-bg strong {
  font-weight: 700;
}
.img-bg li.removing {
  border-bottom: 1px solid #fffafa !important;
}
.img-bg label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 20px;
  font-weight: 700;
}
@media (min-width: 320px) and (max-width: 767px) {
  .img-bg img.card {
    margin: 0 auto !important;
  }
  .img-bg .container-fluid {
    width: 90% !important;
  }
  .img-bg .white-box {
    width: 100%;
    text-align: center;
  }
  .img-bg .white-box h1 {
    text-align: center;
    font-size: 22px;
  }
  .img-bg input#submit {
    margin-left: auto !important;
    margin-right: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-bg .container-fluid {
    width: 90% !important;
  }
  .img-bg .white-box {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .img-bg .col-md-11 {
    flex: 0 0 auto;
    width: -webkit-fill-available;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .img-bg .white-box {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .img-bg .col-md-11 {
    width: 91.66666667% !important;
  }
}
@media (min-width: 1911px) and (max-width: 5000px) {
  .img-bg .container-fluid {
    width: 900px !important;
  }
  .img-bg .white-box {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .img-bg img.card {
    margin: 0 auto !important;
  }
  .img-bg .logo img {
    margin: 20px auto !important;
    width: 260px;
  }
  .img-bg .container-fluid {
    width: 90% !important;
  }

  .img-bg form.form-outer h3 {
    font-size: 18px;
  }
  .img-bg form.form-outer label {
    font-size: 13px;
  }
  .img-bg .white-box {
    padding: 30px 20px;
  }
}
.img-bg label.formsub {
  max-width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: unset !important;
}
.img-bg .box {
  padding: 15px;
  margin: 15px;
  background-color: #00a8e2;
}
.img-bg .check-boexs {
  margin-right: 15px;

  float: left;
  margin-bottom: 20px;
  width: 21.3% !important;
}
.img-bg form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
.img-bg input[type="checkbox"],
.img-bg input[type="radio"] {
  margin: 4px 6px 0 !important;
  margin-top: 1px \9;
  line-height: normal;
}
.img-bg .imgcolor {
  margin-top: 10px;
  box-shadow: 0 10px 28px rgb(0 0 0 / 0%), 0 10px 10px rgb(0 0 0 / 11%);
  height: 130px;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
  margin-right: 8px;
}
.img-bg input.submit-btn {
  background: #3e3935;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #3e3935;
  border-radius: 50px;
}

/* search with select css  */
.img-bg .select_1.dropdown {
  text-align: left;
  border-radius: 30px !important;
}

.img-bg .error-select-search .css-13cymwt-control,
.img-bg .error-select-search .css-t3ipsp-control {
  border: 1px solid red !important;
}
.img-bg .css-13cymwt-control,
.img-bg .css-t3ipsp-control {
  font-size: 14px !important;
  padding-left: 10px;
  background: #e8e8e8 !important;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #dadada !important;
}
.img-bg .css-13cymwt-control:hover {
  border-color: #dadada;
}
.img-bg .css-tj5bde-Svg {
  fill: #a0a0a0 !important;
}
.showPage {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 111111111111;
  background: linear-gradient(
    to bottom,
    #d4efff 0%,
    #ffffff 17%,
    #ffffff 52%,
    #ffffff 83%,
    #d4efff 100%
  );
  background-size: cover !important;
  width: 100%;
  height: 100%;
}
.fix-white {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 90001478523;
}
.img-bg .fix-white {
  display: none;
}
.img-bg .css-1jqq78o-placeholder {
  color: #716e6e !important;
  font-weight: 400;
  font-size: 14px !important;
}
.img-bg .css-1dimb5e-singleValue {
  color: fieldtext !important;
  font-size: 14px !important;
}
.img-bg .react-select__input {
  height: auto !important;
  color: fieldtext !important;
  font-size: 14px !important;

  font-weight: 400 !important;
}

.img-bg form.form-outer input.react-select__input[type="text"] {
  background: 0px center !important;
}

.img-bg .react-select__menu {
  background: #e8e8e8 !important;
  /* color: #888888 !important; */
  color: fieldtext !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.img-bg .css-1xc3v61-indicatorContainer {
  color: hsl(0, 0%, 60%);
}

@media (min-width: 320px) and (max-width: 575px) {
  .img-bg .check-boexs {
    width: 100%;
    text-align: center;
  }
  .img-bg img.imgcolor.img-responsive {
    width: auto;
    margin: 5px auto;
  }
  .img-bg .cat {
    display: initial;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .img-bg .translation-box {
    position: static;
    right: 0px;
    bottom: 0px;
    margin-top: -15px;
    margin-bottom: 0px;
  }
  .img-bg .translation-text {
    text-align: left;
  }
  .img-bg select.goog-te-combo {
    width: 100%;
  }
  .img-bg .recaptacha-design {
    overflow: auto;
    width: 100%;
  }
  .img-bg strong.blue {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-bg .translation-box {
    right: 10px;
    bottom: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .img-bg .container-fluid {
    width: 90% !important;
  }
}
@media (min-width: 1911px) and (max-width: 10000px) {
  .img-bg .container-fluid {
    width: 900px !important;
  }
}